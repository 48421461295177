import React from "react";
import classNames from "classnames";
import { Container, Text, Button, Image } from "@atoms";
import useDefaultsData from "../../craft/staticQueries/DefaultsQuery";

const Cta = ({
  bottomBg,
  button,
  className: _className,
  color,
  copy,
  image,
  paddingBottom,
  paddingTop,
  topBg,
}) => {
  const { cta: defaults } = useDefaultsData();
  return (
    <section
      className={classNames(
        "relative z-20 pt-16 pb-16",
        {
          "md:pt-32": paddingTop,
          "md:pb-32": paddingBottom,
          "bg-midnight": bottomBg === "midnight",
          "bg-carnation": bottomBg === "carnation",
          "bg-mint": bottomBg === "mint",
          "bg-white": bottomBg === "white",
        },
        _className
      )}
    >
      {/* top bg */}
      <div
        className={classNames("absolute inset-x-0 top-0 z-10 h-1/2", {
          "bg-midnight": topBg === "midnight",
          "bg-carnation": topBg === "carnation",
          "bg-mint": topBg === "mint",
          "bg-white": topBg === "white",
        })}
      />

      {/* CTA box */}
      <Container variant="sm" className="relative z-20">
        <div
          className={classNames(
            "flex flex-wrap items-center p-6 md:flex-nowrap md:p-12",
            {
              "bg-mint": color === "mint",
              "bg-carnation": color === "carnation",
              "space-y-8 sm:space-y-0": !image,
            }
          )}
        >
          {/* text */}
          <div
            className={classNames(
              "flex w-full flex-col items-start space-y-4 sm:w-3/5 md:pr-12",
              {
                "order-2 mt-8 sm:order-1 sm:mt-0": image,
              }
            )}
          >
            <Text
              richText
              className={classNames({
                "prose-p:text-lg": !image,
              })}
            >
              {copy || defaults.copy}
            </Text>
            {image && button?.url && (
              <Button
                to={button.url}
                color={color === "mint" ? "carnation" : "midnight"}
              >
                {button.text || "Learn More"}
              </Button>
            )}
          </div>

          {/* divider */}
          {!image && (
            <div className="my-4 hidden w-[2px] self-stretch md:mx-12 md:block">
              <div className="h-full w-full bg-midnight" />
            </div>
          )}

          {/* image or button */}
          <div
            className={classNames(
              "flex shrink flex-col items-center justify-center sm:w-2/5",
              {
                "order-1 sm:order-2": image,
              }
            )}
          >
            {image && (
              <div className="relative aspect-square w-32 md:w-full">
                <Image image={image} fill className="rounded-full" />
              </div>
            )}
            {!image && button?.url && (
              <Button
                to={button.url}
                color={color === "mint" ? "carnation" : "midnight"}
              >
                {button.text}
              </Button>
            )}
            {!image && !button?.url && (
              <Button
                to={defaults.link.url}
                color={color === "mint" ? "carnation" : "midnight"}
              >
                {defaults.link.text}
              </Button>
            )}
          </div>
        </div>
      </Container>

      {/* bottom bg */}
      <div
        className={classNames("absolute inset-x-0 bottom-0 z-10 h-1/2", {
          "bg-midnight": bottomBg === "midnight",
          "bg-carnation": bottomBg === "carnation",
          "bg-mint": bottomBg === "mint",
          "bg-white": bottomBg === "white",
        })}
      />
    </section>
  );
};

Cta.defaultProps = {
  topBg: "midnight",
  bottomBg: "midnight",
  color: "mint",
  paddingBottom: false,
  paddingTop: false,
};

export default Cta;
